body {
  ms-word-wrap: break-word;
}
#wrap {
	_clear:both;
}
#fade {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#CC000000,endColorstr=#CC000000);
  zoom: 1;  
}
.window {
  overflow: auto;
}
.event-create .window-head p {
  display:none;
}
#ifr-get-tickets {
	height:600px;
  min-height:600px;
}

.col-main {
  padding-right:0;
}
.box-wide {
  padding-right:10px;
}
.event-share {
  padding:10px;
}
 
.btn-sub {
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#F8F8F8,endColorstr=#CCCCCC);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=0,startColorstr=#F8F8F8,endColorstr=#CCCCCC)";
}
.btn-sub:hover {
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#EEEEEE,endColorstr=#999999);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=0,startColorstr=#EEEEEE,endColorstr=#999999)";
}
#visual-banner-control-panel button {
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#B2000000,endColorstr=#B2000000);
  zoom: 1;
}

.create-user-ttl {
  padding:30px 0 5px 0;
}
.tos {padding-top:5px;}
#wrap {
  position:relative; 
  z-index: 999999;
}
nav .top-nav {
	float:left; 
	*margin-left:-90px;
}
.nav-account {
  width:200px;
}
.nav-account {
  _display:block;
}
.nav-account li a {
  _padding:0;
}
.nav-account li {
  line-height:100%; 
  text-align:right;
}
.slides .slide2, 
.slides .slide3 {
	_display:none;
}
.event-action {
  padding:15px 0;
}

.box-narrow {
  overflow:hidden;
}
.event-flow, 
.event-flow-help {
	background:transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#CC000000,endColorstr=#CC000000); 
  zoom: 1;
}
.event-flow-content {
  color:#FFF;
}
.ia-handle {
  background-color:transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#CC000000,endColorstr=#CC000000); 
  zoom: 1;
}

.edit-image-btn {
  background-color:transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#CC000000,endColorstr=#CC000000); 
  zoom: 1;
}
.editable-display,
#event-organizer_logo .btn-s#logo-pickfiles,
#event-organizer_logo .btn-s#logo-remove-button {
 	background-color:transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#CC000000,endColorstr=#CC000000); 
  zoom: 1;
}
.form-history-baloon {
  background-color: #FFF;
  zoom: 1;
}
.event-share {
  top:10px;
}
.share-social li.share-url {
  background-color: transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#7FFFFFFF,endColorstr=#7FFFFFFF);
  zoom: 1;
  color:#333;
}
.theme-bk .add-link {
  background-color:#333;
}
.theme-bk .editable:hover {
  background-color:transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#33FFFFFF,endColorstr=#33FFFFFF);
}
.theme-bk .diff-balloon-wrapper {
  background-color:transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#33FFFFFF,endColorstr=#33FFFFFF);
}
.theme-bk .more-toggle span {
  background-color:transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#33FFFFFF,endColorstr=#33FFFFFF);
}
.theme-bk .more-toggle {
  border-top-color: #333;
}
.theme-bk .base-event-view-content .msg-info {
  background-color:#666;
  border:none;
  margin-bottom:0;
}

.activity-meta {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#CC000000,endColorstr=#CC000000); 
  zoom: 1;  
}
#activity-lightbox {
  z-index: 999999;
}
/* DASHBOARD */
tr.db-resend-form>td .resend-form-content:before {
  display: none;
}

/* FOOTER */
footer {
	background:transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#CC000000,endColorstr=#CC000000); 
  zoom: 1;
}
.powered {
	background:transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#CC000000,endColorstr=#CC000000); 
  zoom: 1;
}
.ft-contact {
  background-color:transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#4C000000,endColorstr=#4C000000); 
  zoom: 1;
}
.ft-contact:hover {
  background:transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#CC000000,endColorstr=#CC000000);
  zoom: 1; 
}
.ft-contact small {
  *font-size:14px;
}
/* EVENT STICKY NAV */
.nav-item .icon:before {
  text-align: center;
}

/* LOGIN REGISTER */
.content-main-box,
.recover-pw .content {
  width: 640px;
  margin: 20px auto;
}

.third-party-box .button {
  width: 67px;
  min-width: 67px;
}

.login-other .button-login {
  margin-top: 10px;
}

.recover-pw .field-content .txt-field,
.actions-bar {
  width: 320px;
  margin: 10px auto;
}

.actions-bar .button { 
  display: inline;
  width: auto;
  margin: 0 0.3em;
}

.ticket_front,
.ticket_back {
  border: 1px solid #fff;
  background: none;
}

/* MAMEMAKI CAMPAIGN */
.mamemaki-published-wrap .ribbon-wrap {
  display: none;
}
.mamemaki-published-wrap .mamemaki-content {
  width: 300px;
}
.mamemaki-published-wrap .mamemaki-content:before {
  display: none;
}
.mamemaki-published-wrap .mamemaki-preview-content {
  width: 400px;
  left: 40px;
}
.mamemaki-preview-content {
  background: #000;
}